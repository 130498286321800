<template>
  <div class="page-download">
    <a class="page-download-tag" :href="download_url" :download="download_name">{{ download_name }}</a>
    <img :src="download_url" alt="" v-if="isValid">
    <p v-else>无可下载资源</p>
  </div>
</template>

<script>
export default {
  name: '',
  props: [ 'download_url', 'download_name' ], // $route.query.url $route.query.name
  computed: {
    isValid() {
      if(this.download_url && this.download_url.length>0) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.$nextTick(() => {
      // console.log( $('.page-download') );
      if(this.isValid) {
        document.getElementsByClassName('page-download-tag')[0].click();
      }
      window.close(); // 关闭window.open()打开的标签页
    })
  },
}
</script>

<style lang="scss">
.page-download {
  width: 100vw!important;
  min-height: 100vh!important;
  margin-top: 0!important;
  background-color: #0E0E0E;
  a {
    display: none;
  }
  img {
    width: auto;
    height: auto;
    max-width: 600px;
    max-height: 100vh;
  }
  p {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #ffffff;
    padding: 50px;
  }
  @media (max-width:767px) {
    img {
      max-width: 100vw;
    }
  }
}
</style>